import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import http from "../../../http";

// 监控
export default class Camera extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.manage || 9;

        this.state = {
            cameraUrl: null
        }
    }

    componentDidMount() {
        http.get('/api/big_manage/cameras')
            .then((res) => {
                this.cameras = res.data.cameras
                this.cameraIndex = 0

                this.timerId && clearInterval(this.timerId)
                this.tick()
                this.timerId = setInterval(() => {
                    this.tick()
                }, this.refreshIntervalSeconds * 1000 * 2)
            })
    }

    tick() {
        let camera = this.cameras[this.cameraIndex]
		if (!camera) return
        if (this.cameraIndex >= this.cameras.length-1)
            this.cameraIndex = 0
        else
            this.cameraIndex ++

		if (!camera.iframe_url) return
        console.log(camera)

        this.setState({
            cameraUrl: camera.iframe_url
        })
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">校园监控</div>
                {this.state.cameraUrl ?
                    <iframe src={this.state.cameraUrl} id="ysOpenDevice" allowFullScreen frameBorder="0" style={{width:'95%', height:'90%', margin:'10px'}} /> : ''}
            </BorderBox7>
        )
    }
}
